import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { APIError } from '../../shared/api_errors';
import useAPI from './useAPI';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';
import { parseQueryToPrisma, Query, QueryOperator } from './query';

const OR_PREFIX = 'or_';

export type SortOrder = 'asc' | 'desc';

export interface Operator {
	field: string;
	op: QueryOperator;
}

export interface Condition {
	operator: Operator;
	value?: string;
}

export interface OrCondition {
	conditions: Array<Operator>;
	value?: string;
}

export function isOrCondition(condition: Condition | OrCondition): condition is OrCondition {
	return (condition as OrCondition).conditions !== undefined;
}

export function generateOrConditionKey(or: OrCondition) {
	const fields = or.conditions.map((condition) => condition.field).join('-');
	return `${OR_PREFIX}${fields}`;
}

export interface PaginatedResponse<T> {
	rows: T[];
	records: number;
}

export interface UseCRUDQueryReturn<T> {
	response: PaginatedResponse<T> | null;
	loading: boolean;
	error: APIError | null;
	page: number;
	pageSize: number;
	refetch: (newPage?: number, newPageSize?: number) => Promise<void>;
	setPageSize: (newPageSize: number) => void;
	setPage: (newPage: number) => void;
	sortField?: string;
	sortOrder: SortOrder;
	setOrder: (newSortField: string, newSortOrder: SortOrder) => void;
	query: Query;
	setCondition: (condition: Condition | OrCondition) => void;
	ready: boolean;
	clear: () => void;
	clearError: () => void;
}

export class CRUDQueryOptions {
	initialSortField: string | undefined = undefined;
	initialQuery: Query = {};
	initialPage: number = 1;
	initialPageSize: number = 10;
	initialSortOrder: SortOrder = 'asc';
	performFetch: boolean = true;
	emptyQueryFetch: boolean = true;
	usePagination: boolean = true;
	useSorting: boolean = true;
	asSaas: boolean = false;

	constructor(options?: Partial<CRUDQueryOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

function useCRUDQuery<T>(endpoint: string, options?: Partial<CRUDQueryOptions>): UseCRUDQueryReturn<T> {
	const {
		initialPage,
		initialPageSize,
		initialQuery,
		initialSortField,
		initialSortOrder,
		performFetch,
		emptyQueryFetch,
		usePagination,
		useSorting,
		asSaas,
	} = new CRUDQueryOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const { get } = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [response, setResponse] = useState<PaginatedResponse<T> | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | null>(null);
	const [page, setPage] = useState<number>(initialPage);
	const [pageSize, setPageSize] = useState<number>(initialPageSize);
	const [sortField, setSortField] = useState<string | undefined>(initialSortField);
	const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder);
	const [query, setQuery] = useState<Query>(initialQuery);

	const [debouncedQuery] = useDebounce(query, 500);

	function handleClearError() {
		setError(null);
	}

	function handleSetOrder(newSortField: string, newSortOrder: SortOrder) {
		setSortField(newSortField);
		setSortOrder(newSortOrder);
	}

	function handleSetCondition(condition: Condition | OrCondition) {
		const isOr = isOrCondition(condition);
		const queryKey = isOr ? generateOrConditionKey(condition) : condition.operator.field;

		const value = condition.value;

		if (!value) {
			delete query[queryKey];
			setQuery({ ...query });
			return;
		}

		if (!query[queryKey]) {
			query[queryKey] = {};
		}

		if (isOr) {
			query[queryKey] = condition.conditions.map((c) => ({ [c.field]: { [c.op]: value } }));
		} else {
			query[queryKey] = { [condition.operator.field]: { [condition.operator.op]: value } };
		}

		setQuery({ ...query });
	}

	async function fetchData() {
		if (!tenant || !performFetch) return;
		if (!emptyQueryFetch && !Object.keys(query).length) return;

		setLoading(true);
		setError(null);
		try {
			const parsedQuery = parseQueryToPrisma(debouncedQuery);
			const searchParams = parsedQuery
				? new URLSearchParams({
						page: `${page}`,
						page_size: `${pageSize}`,
						sort_field: `${sortField || ''}`,
						sort_order: sortOrder,
						criteria: JSON.stringify(parsedQuery),
					})
				: new URLSearchParams({
						page: `${page}`,
						page_size: `${pageSize}`,
						sort_field: `${sortField || ''}`,
						sort_order: sortOrder,
					});

			if (!usePagination) {
				searchParams.delete('page');
				searchParams.delete('page_size');
			}

			if (!useSorting) {
				searchParams.delete('sort_field');
				searchParams.delete('sort_order');
			}

			const endpointQuery = [endpoint, searchParams.toString()].filter((o) => o).join('?');
			const response = await get(endpointQuery);
			const result: PaginatedResponse<T> = usePagination
				? (response as PaginatedResponse<T>)
				: ({
						rows: response,
						records: response.length,
					} as PaginatedResponse<T>);
			setResponse(result);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			setResponse(null);
		} finally {
			setLoading(false);
		}
	}

	const refetch = async (newPage?: number, newPageSize?: number): Promise<void> => {
		newPage && setPage(newPage);
		newPageSize && setPageSize(newPageSize);

		// When a refetch is called without parameters, just to refresh data.
		if (!newPage && !newPageSize) {
			await fetchData();
		}
	};

	function clear() {
		setResponse(null);
	}

	useEffect(() => {
		fetchData();
	}, [endpoint, page, pageSize, sortField, sortOrder, debouncedQuery, tenant, performFetch]);

	return {
		response,
		loading,
		error,
		page,
		pageSize,
		refetch,
		setPageSize,
		setPage,
		sortField,
		sortOrder,
		setOrder: handleSetOrder,
		query,
		setCondition: handleSetCondition,
		ready: !!tenant,
		clear,
		clearError: handleClearError,
	};
}

export default useCRUDQuery;
