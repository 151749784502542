import { Client } from './Client';
import { Currency } from './currency';
import { NASPool } from './infra/NAS';
import { PaymentFrequency } from './service/PaymentFrequency';
import { Speed } from './service/Speed';
import { User } from './User';

export interface SubscriptionServiceType {
	id: string;
	service_subscription_id: string;
	service_type_id: string;
	service_type_name: string;
	service_type_description: string;
}

export interface Subscription {
	id: string;
	due_date: number;
	client_id: string;
	client: Client;
	service_plan_id: string;
	service_plan_name: string;
	service_plan_price: number | string;
	service_plan_recurrency_interval: PaymentFrequency;
	service_plan_currency_id: string;
	currency: Currency;
	service_types: SubscriptionServiceType[];
	service_subscription_history: SubscriptionHistory[];
	connectivity_user?: string;
	connectivity_password?: string;
	connectivity_ip?: string;
	connectivity_speed_id: string;
	service_speed: Speed;
	infra_nas_pool_id: string;
	infra_nas_pool: NASPool;
	started_at?: string;
	canceled_at?: string;
	held_at?: string;
	last_successful_payment?: string;
	next_due_date?: string;
	created_at?: string;
	updated_at?: string;
}

export type SubscriptionHistoryModificationType =
	| 'Created'
	| 'Activate'
	| 'Suspend'
	| 'Resume'
	| 'Terminate'
	| 'Updated';

export interface SubscriptionHistory {
	id: string;
	modified_by?: string;
	modification_type: SubscriptionHistoryModificationType;
	message?: string;
	created_at: string;
	user: User;
}

export const subscriptionStatuses = ['Pending', 'Active', 'Terminated', 'Active-&-Suspended'] as const;

// Define the union type based on the constant array
export type SubscriptionStatus = (typeof subscriptionStatuses)[number];

export function getSubscriptionStatus(subscription: Subscription): SubscriptionStatus {
	if (!subscription.started_at) {
		return 'Pending';
	} else if (subscription.canceled_at) {
		return 'Terminated';
	} else if (subscription.started_at && !subscription.canceled_at && subscription.held_at) {
		return 'Active-&-Suspended';
	} else {
		return 'Active';
	}
}

export function getSubscriptionStatusDescription(status: SubscriptionStatus): string {
	const map: { [key in SubscriptionStatus]: string } = {
		Active: 'Active',
		'Active-&-Suspended': 'Active / Suspended',
		Pending: 'Pending',
		Terminated: 'Terminated',
	};

	return map[status];
}
