import React from 'react';

import GenericCRUDPage, { CRUDObj } from './GenericCRUDPage';
import useCRUDPage from '../hooks/useCRUDPage';
import { DataTableField, DataTableAction } from '../components/DataTable';
import { Filter } from '../slices/Filters';

export interface CRUDPageProps<T> {
	title: string;
	endpoint: string;
	formUrl: string;
	initialSortField: string;
	fields: Array<DataTableField<T>>;
	actions?: DataTableAction<T>[];
	filters?: Array<Filter>;
	useDefaultActions?: boolean;
	usePagination?: boolean;
	useSorting?: boolean;
}

export default function CRUDPage<T extends CRUDObj>({
	title,
	endpoint,
	formUrl,
	initialSortField,
	fields,
	actions,
	filters,
	useDefaultActions,
	usePagination = true,
	useSorting = true,
}: CRUDPageProps<T>) {
	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleOnValueChange,
		handleOnOperatorChange,
		handleRemove,
		handleClearError,
	} = useCRUDPage<T>({
		endpoint,
		initialSortField,
		usePagination,
		useSorting,
		filters,
	});

	function handleOnRemove(id: string) {
		handleRemove(id);
	}

	return (
		<GenericCRUDPage
			title={title}
			filters={filters}
			data={data}
			fields={fields}
			actions={actions}
			error={error}
			loading={loading}
			page={page}
			pageSize={pageSize}
			totalRecords={totalRecords}
			sortField={sortField}
			sortOrder={sortOrder}
			formUrl={formUrl}
			useDefaultActions={useDefaultActions}
			onPageSize={handleOnPageSize}
			onPage={handleOnPage}
			onRefresh={handleOnRefresh}
			onSort={handleOnSort}
			onValueChange={handleOnValueChange}
			onOperatorChange={handleOnOperatorChange}
			onRemove={handleOnRemove}
			onClearError={handleClearError}
		/>
	);
}
