import { Subscription } from './models/Subscription';

export function generateRandomId(length: number): string {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
}

export function toLocaleNumberString(n: number, locale: string = navigator.language, decimalDigits: number = 2) {
	return n.toLocaleString(locale, {
		maximumFractionDigits: decimalDigits,
		minimumFractionDigits: decimalDigits,
	});
}

export function timeSince(dateString: string): string {
	const date = new Date(dateString);
	const now = new Date();
	const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

	const intervals: { [key: string]: number } = {
		year: 31536000, // 60 * 60 * 24 * 365
		month: 2592000, // 60 * 60 * 24 * 30
		day: 86400, // 60 * 60 * 24
		hour: 3600, // 60 * 60
		minute: 60,
		second: 1,
	};

	for (const [key, value] of Object.entries(intervals)) {
		const interval = Math.floor(seconds / value);
		if (interval >= 1) {
			if (key === 'day' && interval === 1) return 'yesterday'; // Special case
			return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
		}
	}
	return 'just now';
}

// Example usage:
console.log(timeSince('2024-10-23T14:49:43.228Z')); // Output: "just now" (or close to it)

export function toLocaleDateString(isoDateString: string) {
	const userLocale = navigator.language || 'en-US';
	const date = new Date(isoDateString);
	const localeDateString = date.toLocaleDateString(userLocale, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
	return localeDateString;
}

export function dueDateValueExtractor(form: Subscription) {
	const dueDate = form.due_date;
	if (!dueDate) return '';

	let suffix;

	switch (dueDate) {
		case 1:
			suffix = 'st';
			break;
		case 2:
			suffix = 'nd';
			break;
		case 3:
			suffix = 'rd';
			break;
		default:
			suffix = 'th';
	}

	return `every day ${dueDate}${suffix}`;
}

export function getCardBrandLogoUrl(cardBrand: string): string {
	const cardBrandLogos: { [key: string]: string } = {
		visa: '/imgs/cards/light/visa.png',
		mastercard: '/imgs/cards/light/mastercard.png',
		amex: '/imgs/cards/light/amex.png',
		discover: '/imgs/cards/light/discover.png',
		jcb: '/imgs/cards/light/jcb.png',
		diners: '/imgs/cards/light/diners.png',
		unionpay: '/imgs/cards/light/unionpay.png',
	};

	const normalizedBrand = cardBrand.toLowerCase();
	return cardBrandLogos[normalizedBrand] || '/imgs/cards/light/default.png';
}
