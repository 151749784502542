export type QueryOperator = 'eq' | 'lt' | 'le' | 'gt' | 'ge' | 'ne' | 'like' | 'ilike' | 'in' | 'not_null' | 'null';

export interface QueryCondition {
	[field: string]: {
		[operator: string]: string;
	};
}

export interface Query {
	[key: string]: QueryCondition | QueryCondition[];
}

// Extend PrismaCondition to support different query operations
type PrismaCondition = {
	equals?: string | number | boolean | null;
	not?: string | number | boolean | null | { equals?: null };
	lt?: number;
	lte?: number;
	gt?: number;
	gte?: number;
	contains?: string;
	mode?: 'insensitive';
	in?: Array<string | number | boolean>;
};

// Updated PrismaWhereClause type
type PrismaWhereClause = {
	[key: string]: PrismaCondition | PrismaWhereClause | PrismaWhereClause[] | undefined;
	OR?: PrismaWhereClause[];
	AND?: PrismaWhereClause[];
	NOT?: PrismaWhereClause[];
};

export function parseQueryToPrisma(query: Query): PrismaWhereClause {
	const prismaQuery: PrismaWhereClause = {};
	for (const key in query) {
		const conditionOrArray = query[key];
		if (Array.isArray(conditionOrArray)) {
			// If the query[key] is an array, accumulate it in Prisma's OR condition
			prismaQuery.OR = accumulateConditions(prismaQuery.OR, conditionOrArray.map(mapConditionToPrisma));
		} else {
			// If it's a single condition, accumulate it in Prisma's AND format
			prismaQuery.AND = accumulateConditions(prismaQuery.AND, [mapConditionToPrisma(conditionOrArray)]);
		}
	}

	return prismaQuery;
}

// Helper function to accumulate conditions in an array
function accumulateConditions(
	existingConditions: PrismaWhereClause[] | undefined,
	newConditions: PrismaWhereClause[],
): PrismaWhereClause[] {
	return existingConditions ? [...existingConditions, ...newConditions] : newConditions;
}

function mapConditionToPrisma(condition: QueryCondition): PrismaWhereClause {
	const prismaCondition: PrismaWhereClause = {};

	for (const field in condition) {
		const operatorValue = condition[field];
		for (const operator in operatorValue) {
			const value = operatorValue[operator];

			switch (operator as QueryOperator) {
				case 'eq':
					prismaCondition[field] = { equals: value };
					break;
				case 'ne':
					prismaCondition[field] = { not: { equals: value } };
					break;
				case 'lt':
					prismaCondition[field] = { lt: Number(value) };
					break;
				case 'le':
					prismaCondition[field] = { lte: Number(value) };
					break;
				case 'gt':
					prismaCondition[field] = { gt: Number(value) };
					break;
				case 'ge':
					prismaCondition[field] = { gte: Number(value) };
					break;
				case 'like':
					prismaCondition[field] = { contains: value };
					break;
				case 'ilike':
					prismaCondition[field] = { contains: value, mode: 'insensitive' };
					break;
				case 'in':
					prismaCondition[field] = { in: value.split(',').map((v) => (isNaN(Number(v)) ? v : Number(v))) };
					break;
				case 'not_null':
					prismaCondition[field] = { not: { equals: null } };
					break;
				case 'null':
					prismaCondition[field] = { equals: null };
					break;
				default:
					// Handle unsupported operators
					throw new Error(`Unsupported operator: ${operator}`);
			}
		}
	}

	return prismaCondition;
}
