import React from 'react';

import { DataTableField } from '../../components/DataTable';
import { Filter } from '../../slices/Filters';

import CRUDPage from '../CRUDPage';
import { Type } from '../../shared/models/service/Type';

const fields: Array<DataTableField<Type>> = [
	{ title: 'Name', property: 'name' },
	{
		title: 'Speed',
		property: 'service_speed.description',
		extractor: (row: Type) => row.service_speed?.description || '',
	},
];
const endpoint = '/service/type';
const formUrl = '/service/types/new';

export default function TypePage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'ilike',
			},
		},
	];

	return (
		<CRUDPage
			title="Types"
			endpoint={endpoint}
			initialSortField="name"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
