import React, { useState } from 'react';

import { DataTableAction, DataTableField } from '../components/DataTable';
import { Filter, Or } from '../slices/Filters';

import { User } from '../shared/models/User';
import { useProfile } from '../hooks/useProfile';
import useAPIUsers from '../hooks/api/useAPIUsers';
import ConfimationBox from '../slices/ConfirmationBox';
import GenericCRUDPage from './GenericCRUDPage';
import useCRUDPage from '../hooks/useCRUDPage';

const endpoint = '/management/users';

export default function UserPage() {
	const { currentIsSaas: saasAdmin, tenant } = useProfile();
	const { revoke, access, error: apiUsersError } = useAPIUsers();
	const [selectedUser, setSelectedUser] = useState<{ user: User; action: 'revoke' | 'enable/disabled' } | undefined>(
		undefined,
	);

	const filters: Array<Filter> = [
		{
			id: 'or',
			label: 'Name:',
			operator: (): Or => {
				return {
					operators: [
						{ field: 'given_name', op: 'ilike' },
						{ field: 'family_name', op: 'ilike' },
					],
				};
			},
		},
	];

	const {
		data,
		error,
		loading,
		page,
		pageSize,
		totalRecords,
		sortField,
		sortOrder,
		refetch,
		handleOnPageSize,
		handleOnPage,
		handleOnRefresh,
		handleOnSort,
		handleOnValueChange,
		handleOnOperatorChange,
		handleRemove,
	} = useCRUDPage<User>({
		endpoint,
		filters,
	});

	let fields: Array<DataTableField<User>> = [
		{ title: 'Name', property: 'name', extractor: (row: User) => `${row.given_name} ${row.family_name}` },
		{
			title: 'Email',
			property: 'email',
			extractor: (row: User) => (
				<span className={row.enabled ? 'font-bold' : 'text-light'}>
					{!row.enabled ? `${row.email} (disabled)` : row.email}
				</span>
			),
		},
		{
			title: 'SaaS Role',
			property: 'saas_role',
			extractor: (row: User) => row.saas_role,
		},
		{
			title: saasAdmin ? 'ISP Roles' : 'Role',
			property: 'isps',
			extractor: (row: User) =>
				row.user_isp?.map((isp) => (
					<p key={isp.isp_id}>
						{saasAdmin && (
							<>
								<span className="font-bold">{isp.isp.name}</span>:&nbsp;
							</>
						)}
						<span>{isp.role}</span>
					</p>
				)),
		},
	];

	let actions: Array<DataTableAction<User>> = [
		{
			icon: 'UilPlug',
			title: 'Enable/Disable',
			action: function (row: User): void {
				setSelectedUser({ user: row, action: 'enable/disabled' });
			},
		},
		{
			icon: 'UilCloudBlock',
			title: `Revoke from ${tenant?.name}`,
			action: function (row: User): void {
				setSelectedUser({ user: row, action: 'revoke' });
			},
		},
	];

	if (!saasAdmin) {
		fields = fields.filter((field) => field.property !== 'saas_role');
		actions = actions.filter((action) => action.title !== 'Enable/Disable');
	} else {
		actions = actions.filter((action) => !action.title.startsWith('Revoke from'));
	}

	async function handleRevokeOk() {
		if (!selectedUser) return;
		setSelectedUser(undefined);
		if (selectedUser.action === 'revoke') await revoke(selectedUser.user.id);
		else await access(selectedUser.user.id);
		await refetch();
	}

	return (
		<GenericCRUDPage
			title="Users"
			filters={filters}
			data={data}
			fields={fields}
			actions={actions}
			error={error || apiUsersError}
			loading={loading}
			page={page}
			pageSize={pageSize}
			totalRecords={totalRecords}
			sortField={sortField}
			sortOrder={sortOrder}
			formUrl="/users/new"
			useDefaultActions={saasAdmin}
			onPageSize={handleOnPageSize}
			onPage={handleOnPage}
			onRefresh={handleOnRefresh}
			onSort={handleOnSort}
			onValueChange={handleOnValueChange}
			onOperatorChange={handleOnOperatorChange}
			onRemove={handleRemove}
		>
			{selectedUser && (
				<ConfimationBox
					title="Action Confirmation"
					message={`Are you sure you want to ${selectedUser.action} access for this user?`}
					confirmationText={selectedUser.user.family_name}
					onOk={handleRevokeOk}
					onCancel={() => setSelectedUser(undefined)}
				/>
			)}
		</GenericCRUDPage>
	);
}
