/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import {
	Subscription,
	SubscriptionHistoryModificationType,
	SubscriptionStatus,
	getSubscriptionStatus,
} from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { BeforeSubmitType, useCRUDFormPage } from '../hooks/useCRUDForm';
import GenericCRUDFormPage from './GenericCRUDFormPage';
import { useCreateSubscriptionGroups } from '../hooks/subscription/useCreateSubscriptionGroups';
import { readOnlyGroups } from '../constants/readOnlyGroups';
import { BottomControlCustomAction } from '../slices/BottomControl';
import useAPISubscription from '../hooks/api/useAPISubscription';
import { ButtonIcon } from '../components/Button';
import ConfimationBox from '../slices/ConfirmationBox';
import WhiteBox from '../components/WhiteBox';
import FormGroup from '../slices/FormGroup';
import UilIcon from '../components/UilIcon';
import { IconType } from '@iconscout/react-unicons';
import { TailwindTextColorClass, TailwindWidthProportionClass } from '../shared/tailwind';
import { timeSince } from '../shared/utils';

const createUrl = '/subscriptions/new';
const endpoint = '/service/subscriptions';

function handleAfterFetch(data: Subscription): object {
	const form = { ...data, price: '' };
	form.service_plan_price = `${data.currency.symbol || ''} ${applyMask((data.service_plan_price as number).toString())}`;

	return form;
}

function handleBeforeSubmit(payload: BeforeSubmitType, form: Subscription) {
	const changedPayload: BeforeSubmitType = {};
	if (!form.id) {
		changedPayload.due_date = parseInt(form.due_date.toString());
		changedPayload.client_id = form.client_id;
		changedPayload.plan_id = form.service_plan_id;
		changedPayload.pool_id = form.infra_nas_pool_id;
	}

	changedPayload.connectivity_user = form.connectivity_user || '';
	changedPayload.connectivity_password = form.connectivity_password || '';
	changedPayload.connectivity_ip = form.connectivity_ip || null;

	return changedPayload;
}

const PLAY_ICON: ButtonIcon = {
	type: 'UilPlay',
};

const PAUSE_ICON: ButtonIcon = {
	type: 'UilPause',
};

const RESUME_ICON: ButtonIcon = {
	type: 'UilForward',
};

const STOP_ICON: ButtonIcon = {
	type: 'UilSquareFull',
};

const HISTORY_ICON: {
	[key in SubscriptionHistoryModificationType]: { type: IconType; color: TailwindTextColorClass };
} = {
	Created: { type: 'UilPlusCircle', color: 'text-light' },
	Activate: { type: PLAY_ICON.type, color: 'text-success' },
	Suspend: { type: PAUSE_ICON.type, color: 'text-warning' },
	Resume: { type: RESUME_ICON.type, color: 'text-success' },
	Terminate: { type: STOP_ICON.type, color: 'text-danger' },
	Updated: { type: 'UilSync', color: 'text-info' },
};

function SubscriptionByIdPage() {
	const [groups, setGroups] = useState<CRUDFormGroupType[]>([]);
	const [confirmTerminate, setConfirmTerminate] = useState(false);

	const { id, form, setForm, handleSave, error, clearError, loading } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		handleAfterFetch,
		handleBeforeSubmit,
	);
	const {
		activate,
		terminate,
		toggleHold,
		loading: subscriptionLoading,
		error: subscriptionError,
		clearError: subscriptionClearError,
	} = useAPISubscription();
	const isCreation = id === 'new';

	const subscription = form as Subscription;
	const { groups: createGroups } = useCreateSubscriptionGroups(subscription, setForm);

	useEffect(() => {
		setGroups(isCreation ? createGroups : readOnlyGroups);
	}, [id, createGroups]);

	async function handleActivate() {
		if (!id || isCreation) return;
		clearError();
		const result = await activate(id);
		if (result) setForm(result);
	}

	async function handleToggleHold() {
		if (!id || isCreation) return;
		clearError();
		const result = await toggleHold(id);
		if (result) setForm(result);
	}

	async function handleTerminate() {
		setConfirmTerminate(true);
	}

	async function handleTerminateOk() {
		if (!id || isCreation) return;
		clearError();
		const result = await terminate(id);
		if (result) setForm(result);
		setConfirmTerminate(false);
	}

	function getCustomAction(subscription: Subscription) {
		const status = getSubscriptionStatus(subscription);
		const loadingIcon: ButtonIcon | undefined = subscriptionLoading
			? {
					type: 'UilSpinner',
					spin: true,
				}
			: undefined;
		const actions: {
			[K in SubscriptionStatus]: BottomControlCustomAction | undefined;
		} = {
			['Pending']: {
				title: 'Activate',
				variant: 'success',
				action: handleActivate,
				icon: loadingIcon ? loadingIcon : PLAY_ICON,
			},
			['Active']: {
				title: 'Terminate',
				variant: 'danger',
				action: handleTerminate,
				icon: loadingIcon ? loadingIcon : STOP_ICON,
			},
			['Active-&-Suspended']: {
				title: 'Terminate',
				variant: 'danger',
				action: handleTerminate,
				icon: loadingIcon ? loadingIcon : STOP_ICON,
			},
			['Terminated']: undefined,
		};

		const holdAction: BottomControlCustomAction | undefined =
			['Active', 'Active-&-Suspended', 'Terminated'].indexOf(status) > -1
				? {
						title: subscription.held_at ? 'Resume' : 'Suspend',
						variant: subscription.held_at ? 'success' : 'warning',
						action: handleToggleHold,
						icon: loadingIcon ? loadingIcon : subscription.held_at ? RESUME_ICON : PAUSE_ICON,
					}
				: undefined;

		const action = subscription.id && actions[status];
		if (!action) return;

		const result = [];
		if (holdAction) {
			result.push(holdAction);
		}
		result.push(action);

		return result;
	}

	function clearErrors() {
		clearError();
		subscriptionClearError();
	}

	return (
		<>
			<GenericCRUDFormPage
				title="Subscription"
				groups={groups}
				backwardUrl="/subscriptions"
				createUrl={createUrl}
				form={form as never}
				setForm={setForm}
				loading={loading}
				handleSave={handleSave}
				error={error || subscriptionError}
				onClearError={clearErrors}
				customActions={getCustomAction(subscription)}
			>
				{!isCreation && (
					<FormGroup title="History">
						<div className="max-h-96 overflow-y-scroll">
							<ul className="flex flex-col gap-2">
								{subscription.service_subscription_history
									?.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
									.map((history) => (
										<li
											key={history.id}
											className="pb-2 border-b border-gray-200 last:border-none flex items-center gap-2"
										>
											<UilIcon
												type={HISTORY_ICON[history.modification_type].type}
												className={HISTORY_ICON[history.modification_type].color}
											/>
											<div className="flex flex-col">
												<p>
													<span className="text-sm">{`${history.user.given_name} ${history.user.family_name}`}</span>
													&nbsp;
													<span className="text-sm font-bold w-32">
														{timeSince(history.created_at)}
													</span>
												</p>
												<p>
													<span className="text-sm">{history.message}</span>
												</p>
											</div>
										</li>
									))}
							</ul>
						</div>
					</FormGroup>
				)}
			</GenericCRUDFormPage>
			{confirmTerminate && (
				<ConfimationBox
					title="Action Confirmation"
					message="Are you sure you want to terminate this subscription? You may not be allowed to rollback!"
					onOk={handleTerminateOk}
					onCancel={() => setConfirmTerminate(false)}
					inverted
				/>
			)}
		</>
	);
}

export default SubscriptionByIdPage;
